import * as React from "react";
import Seo from "../components/seo";
import {graphql} from "gatsby";
import * as styles from "./flat.module.scss";
import Gallery from "@browniebroke/gatsby-image-gallery";
import Layout from "../components/layout/layout";

const FlatLevel1Page = ({data}) => {
  const flat = data?.api?.flats[0];

  const images = flat?.gallery?.map(({file}, index) => ({
    ...file.childImageSharp,
    caption: `Obrázek ${index + 1}`
  }));

  const lightboxOptions = {
    imageLoadErrorMessage: 'Nelze načíst obrázek',
    nextLabel: 'Další',
    prevLabel: 'Předchozí',
    zoomInLabel: 'Přiblížit',
    zoomOutLabel: 'Oddálit',
    closeLabel: 'Zavřít',
  }

  return (
    <>
      <Seo title={flat?.id} />

      <Layout contactInfo={data.api.contactInfo}>
        <div className={styles.container}>
          <h2>Úroveň I</h2>
          <div className={styles.text} dangerouslySetInnerHTML={{__html: flat?.text}} />
          {images?.length > 0 && (
            <div className={styles.galleryWrapper}>
              <h4>Galerie</h4>
              <Gallery images={images} lightboxOptions={lightboxOptions} imgClass={styles.galleryImage} />
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
    query FlatLevel1PageQuery($filter: API_JsonType) {
        api {
            flats:flatsCollection(filter: $filter) {
                id
                text:level1Text
                gallery:level1Gallery {
                    path
                    file {
                        childImageSharp {
                            thumb: gatsbyImageData(
                                width: 270
                                height: 270
                                placeholder: BLURRED
                            )
                            full: gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            contactInfo:contactSingleton {
                email
                phone
                name
            }
        }
    }
`;

export default FlatLevel1Page;
