// extracted by mini-css-extract-plugin
export var card = "flat-module--card--3d8Lb";
export var container = "flat-module--container--WoZMf";
export var copyright = "flat-module--copyright--NWyQ4";
export var galleryImage = "flat-module--gallery-image--1Qe3W";
export var galleryWrapper = "flat-module--gallery-wrapper--1pi3T";
export var highlighted = "flat-module--highlighted--39rCn";
export var layoutWrapper = "flat-module--layout-wrapper--3EqiJ";
export var level = "flat-module--level--3Sp0X";
export var main = "flat-module--main--3zZVA";
export var price = "flat-module--price--YinGl";
export var priceLevels = "flat-module--price-levels--2X3Id";
export var priceLevelsTitle = "flat-module--price-levels-title--3NpVD";
export var text = "flat-module--text--2dkrV";